import equal from 'fast-deep-equal';

import {CurrentUser} from 'redux/reducers/user';
import {FeatureFlag} from 'toolkit/feature-flags/types';
import Format from 'toolkit/format/format';
import {isAtLeast, PUBLIC_VENDOR_ID} from 'toolkit/users/utils';
import * as Types from 'types';
import {ascendingBy} from 'utils/arrays';

import {MatchStatus} from './types';

export function isProductMappingWizardVisible(currentUser: CurrentUser): boolean {
  return (
    currentUser.vendor.id !== PUBLIC_VENDOR_ID &&
    isAtLeast(currentUser.user.role, Types.Role.SUPER_ADMIN) &&
    currentUser.featureFlags.has(FeatureFlag.PRODUCT_MAPPING_WIZARD)
  );
}

export function areConfigurationsEqual(
  configurationA: readonly Types.Attribute[] | null,
  configurationB: readonly Types.Attribute[] | null
): boolean {
  if (configurationA === null && configurationB === null) {
    return true;
  }
  if (configurationA === null || configurationB === null) {
    return false;
  }
  if (configurationA.length !== configurationB.length) {
    return false;
  }
  return equal(
    [...configurationA].sort(ascendingBy(attribute => attribute.id)),
    [...configurationB].sort(ascendingBy(attribute => attribute.id))
  );
}

export function cartesian<T>(a: readonly T[], b: readonly T[]): T[][] {
  return a.flatMap(aT => b.map(bT => [aT, bT]));
}

export function formatPercent(percent?: number): string {
  if (percent === undefined) {
    return '';
  }
  return Format.percent(percent, {
    upperLimit: 0.99,
    lowerLimit: 0.01,
    fractionalDigitCount: 0,
  });
}

const MATCH_STATUS_ORDER: {[key in MatchStatus]: number} = {
  [MatchStatus.UNCHANGED_UNMATCHED]: 0,
  [MatchStatus.NEWLY_UNMATCHED]: 1,
  [MatchStatus.MATCH_CHANGED]: 2,
  [MatchStatus.NEWLY_MATCHED]: 3,
  [MatchStatus.UNCHANGED_MATCHED]: 4,
};
export function matchStatusComparator(a: MatchStatus, b: MatchStatus): number {
  return MATCH_STATUS_ORDER[a] - MATCH_STATUS_ORDER[b];
}
