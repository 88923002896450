import equal from 'fast-deep-equal';
import {Set} from 'immutable';
import moment from 'moment-timezone';
import {v4 as uuid} from 'uuid';

import * as Api from 'api';
import {tracker} from 'app/PageLoadTracker';
import {AnalysisAction} from 'redux/actions/analysis';
import ActionType from 'redux/actions/types';
import {CurrentUser} from 'redux/reducers/user';
import {fetchComputeResult, fetchPlanningComputeResult} from 'toolkit/compute/api';
import {ComputeResultExtended, ThinComputeResultRowExtended} from 'toolkit/compute/types';
import {flattenFilterMap, withoutGraphContext} from 'toolkit/filters/utils';
import {DATE_FORMAT} from 'toolkit/format/constants';
import {
  DateGranularity,
  getCalendarConfig,
  getCalendarPeriod,
  getCalendarType,
} from 'toolkit/time/calendar-utils';
import {
  AttributeHierarchyTreeResult,
  AttributeHierarchyWidgetData,
  AttributeResult,
  CompositeResult,
  DataProviderOptions,
  EventWidgetData,
  PlanningWidgetData,
  ViewState,
  WidgetData,
  WidgetDataProvider,
} from 'toolkit/views/types';
import * as Types from 'types';
import {FetchExtras} from 'utils/ajax';
import {assertNonNullish, assertTruthy} from 'utils/assert';
import {isNullish, isTruthy} from 'utils/functions';

import {
  createComputeRequest,
  getEffectiveWidgetFilters,
  getUnionOfWidgetPeriods,
  Widgets,
} from './utils';

export function updateComputeOptions(
  options: Types.ComputeOptions | null,
  updatedOptions: Partial<Types.ComputeOptions>
): Types.ComputeOptions {
  return {
    ...(options || {
      includeTargetMetadata: false,
      includeEmptyDates: false,
      batch: false,
      filterFulfillmentMethods: false,
      allowIncompleteDisaggregation: false,
    }),
    ...updatedOptions,
  };
}

export function makeComputeRequest(
  dataProviderOptions: DataProviderOptions,
  computeOptions?: Partial<Types.ComputeOptions>
) {
  const baseRequest = getBaseComputeRequest(dataProviderOptions);
  return fetchComputeResult(
    {
      ...baseRequest,
      options: updateComputeOptions(baseRequest.options, computeOptions ?? {}),
    },
    dataProviderOptions.activeView.view.id?.toString(),
    dataProviderOptions.activeView.view.widgets[dataProviderOptions.widgetIndex].id?.toString()
  );
}

function getBaseComputeRequest(options: DataProviderOptions) {
  const config = options.activeView.view.widgets[options.widgetIndex];
  return getComputeRequest(options, config);
}

export function makeDiagnosticsRequest(options: DataProviderOptions) {
  return Api.VendorDiagnostics.fetchOrGenerateInsights(getDiagnosticsRequest(options));
}

function getDiagnosticsRequest({
  activeView,
  currentUser,
}: DataProviderOptions): Types.DiagnosticsRequest {
  const calendar = currentUser.settings.analysisSettings.calendar;
  const evaluationDate = activeView.evaluationDate || moment();

  return {
    calendar,
    evaluationDate: evaluationDate.format(DATE_FORMAT),
    // TODO: add selector in widget ui
    unit: Types.CalendarUnit.WEEKS,
    filters: activeView.view.filters,
  };
}

export async function makeComputeAndEventRequest(
  dataProviderOptions: DataProviderOptions,
  computeOptions?: Partial<Types.ComputeOptions>
) {
  const [compute, events] = await Promise.all([
    makeComputeRequest({...dataProviderOptions, planType: null, planDiff: null}, computeOptions),
    makeEventsRequest({...dataProviderOptions, planType: null, planDiff: null}),
  ]);
  return new CompositeResult<Types.CalendarEventResult | ComputeResultExtended>({compute, events});
}

export async function makeChartComputeRequest(options: DataProviderOptions) {
  return makeComputeAndEventRequest(options, {includeEmptyDates: true});
}

export function makeTableComputeRequest(options: DataProviderOptions) {
  return makeComputeRequest(options, {includeTargetMetadata: true});
}

export async function makePlanningRequest(
  options: DataProviderOptions,
  extras?: FetchExtras
): Promise<NonNullable<PlanningWidgetData['data']>> {
  const config = options.activeView.view.widgets[options.widgetIndex];
  const request = getPlanningComputeRequest(options, config);

  const [compute, events] = await Promise.all([
    fetchPlanningComputeResult(request, extras),
    makeEventsRequest({...options, planType: null, planDiff: null}, extras),
  ]);
  return new CompositeResult<ComputeResultExtended | Types.CalendarEventResult>({
    compute,
    events,
  });
}

export async function chooseFilterAttributes({
  activeView,
  widgetIndex,
}: DataProviderOptions): Promise<AttributeResult> {
  const config = activeView.view.widgets[widgetIndex];
  const rowGroupings = assertTruthy(config.rowGroupings);

  return Promise.resolve(
    new AttributeResult({
      attributeInstances: rowGroupings,
    })
  );
}

export async function chooseFilterAttributesAndAugmentWithData({
  activeView,
  widgetIndex,
  removeDoubleCountingPartners,
}: DataProviderOptions): Promise<NonNullable<AttributeHierarchyWidgetData['data']>> {
  const config = activeView.view.widgets[widgetIndex];
  const filters = getWidgetFilters(activeView, config, widgetIndex);
  const attributeInstances = config.rowGroupings;

  return new CompositeResult<AttributeResult | AttributeHierarchyTreeResult>({
    attribute: new AttributeResult({attributeInstances: attributeInstances || []}),
    attributeHierarchyTree: new AttributeHierarchyTreeResult({
      tree: await getAnalysisSidebarTree(
        attributeInstances,
        filters,
        removeDoubleCountingPartners ?? false
      ),
    }),
  });
}

function getAnalysisSidebarTree(
  attributeInstances: readonly Types.AttributeInstance[],
  filters: readonly Types.AttributeFilter[],
  removeDoubleCountingPartners: boolean
): Promise<Types.AttributeHierarchyTree> {
  return Api.Views.getRecommendedFilterTree({
    attributes: attributeInstances.map(attributeInstance => attributeInstance.attribute),
    filters,
    removeDoubleCountingPartners,
  });
}

function getEventsPeriodForWidget(activeView: ViewState, widgetIndex: number) {
  const config = activeView.view.widgets[widgetIndex];
  const widgetData = activeView.widgetData[widgetIndex];
  const dataType = Widgets[config.type].dataType;
  const calendarType = getCalendarType(config.type);

  if (calendarType) {
    const selectedDate =
      (dataType === 'EVENT' &&
        widgetData &&
        (widgetData as EventWidgetData).ephemeralData.currentDate) ||
      moment(activeView.evaluationDate);
    return getCalendarPeriod(selectedDate, calendarType);
  }

  return getUnionOfWidgetPeriods(config) || activeView.view.evaluationPeriod;
}

function getWidgetCalendarGranularities(config: Types.Widget): Set<DateGranularity> {
  const calendarType = getCalendarType(config.type);
  if (!calendarType) {
    return Set();
  }

  const calendarConfig = getCalendarConfig(calendarType);
  return Set.of(calendarConfig.rowGranularity, calendarConfig.cellGranularity, calendarType).filter(
    isTruthy
  );
}
export function makeEventsRequest(
  {activeView, widgetIndex}: DataProviderOptions,
  extras?: FetchExtras
) {
  const config = activeView.view.widgets[widgetIndex];

  const request: Types.CalendarEventsRequest = {
    calendar: activeView.view.calendar,
    calendarGranularities: getWidgetCalendarGranularities(config).toArray(),
    evaluationDate: moment(activeView.evaluationDate).format(DATE_FORMAT),
    eventFilter: null,
    filters: getBaseWidgetFilters(activeView, widgetIndex),
    period: getEventsPeriodForWidget(activeView, widgetIndex),
  };
  return Api.CalendarEvents.getEvents(request, extras);
}

export function fetchWidgetData(
  activeView: ViewState,
  viewIndex: number,
  widgetIndex: number,
  currentUser: CurrentUser,
  dataProvider: WidgetDataProvider
): AnalysisAction {
  const config = activeView.view.widgets[widgetIndex];
  const requestId = uuid();

  const promise = tracker.trackWidgetDataProvider(activeView.view, widgetIndex, () =>
    dataProvider({
      activeView,
      widgetIndex,
      planType: null,
      planDiff: null,
      currentUser,
      removeDoubleCountingPartners: activeView.view.removeDoubleCounting ?? false,
      requestId,
    })
  );

  return {
    dataKey: config,
    promise,
    requestId,
    timestamp: moment(),
    type: ActionType.SetWidgetData,
    viewIndex,
  };
}

function getBaseWidgetFilters(activeView: ViewState, widgetIndex: number) {
  const config = activeView.view.widgets[widgetIndex];
  return getWidgetFilters(activeView, config, widgetIndex);
}

function getWidgetFilters(activeView: ViewState, config: Types.Widget, configIndex: number) {
  if (Widgets[config.type].isFilterWidget) {
    return getEffectiveWidgetFilters(activeView.view.filters, config);
  }
  const selectionFilters = flattenFilterMap(
    activeView.selectionFiltersByWidgetIndex.filter((_, widgetIndex) => widgetIndex !== configIndex)
  );
  const adjustedSelectionFilters = config.ignoreGraphContextInFilters
    ? selectionFilters.map(withoutGraphContext)
    : selectionFilters;

  return getEffectiveWidgetFilters(activeView.view.filters, config).concat(
    adjustedSelectionFilters
  );
}

export function setVersionRecencyForMetrics(
  metrics: readonly Types.MetricInstance[],
  versionRecency: Types.VersionRecency | null
): readonly Types.MetricInstance[] {
  return metrics.map(metric => ({
    ...metric,
    arguments: {
      ...metric.arguments,
      versionRecency,
    },
  }));
}

export function setVersionRecencyForMetricFilters(
  metricFilters: ReadonlyArray<readonly Types.MetricFilter[]>,
  versionRecency: Types.VersionRecency | null
): ReadonlyArray<readonly Types.MetricFilter[]> {
  return metricFilters.map(filters =>
    filters.map(metricFilter => ({
      ...metricFilter,
      metric: {
        ...metricFilter.metric,
        arguments: {
          ...metricFilter.metric.arguments,
          versionRecency,
        },
      },
    }))
  );
}

function getComputeRequest(
  {activeView, widgetIndex, requestId}: DataProviderOptions,
  config: Types.Widget
): Types.ComputeRequest {
  const calendar = activeView.view.calendar;
  const evaluationDate = activeView.evaluationDate!;
  const removeDoubleCounting = activeView.view.removeDoubleCounting;

  const baseComputeRequest = createComputeRequest(
    calendar,
    config,
    evaluationDate,
    requestId,
    activeView.computeEvaluationDateTime,
    removeDoubleCounting
  );

  return {
    ...baseComputeRequest,
    filters: getWidgetFilters(activeView, config, widgetIndex),
    options: {
      ...(config.options || {}),
      allowIncompleteDisaggregation: false,
      includeTargetMetadata: false,
      filterFulfillmentMethods: config.filterFulfillmentMethods,
      includeEmptyDates: false,
      batch: false,
    },
  };
}

function getPlanningComputeRequest(
  options: DataProviderOptions,
  config: Types.Widget
): Types.PlanComputeRequest {
  return {
    computeRequest: getComputeRequest(options, config),
    planDiff: options.planDiff ?? null,
    planType: assertNonNullish(options.planType),
  };
}

function mergeComputeResultData(
  dataA: Types.ThinComputeResultRow,
  dataB: Types.ThinComputeResultRow
): Types.ThinComputeResultRow {
  if (!equal(dataA.value, dataB.value)) {
    throw Error('not the same node value in tree');
  }

  const children = dataA.children.map((child, index) =>
    mergeComputeResultData(child, dataB.children[index] ?? getNaNRowChild(child))
  );
  return {
    children,
    columnData: mergeColumnData(dataA.columnData, dataB.columnData),
    value: dataA.value,
  };
}

function getNaNRowChild(otherChild: Types.ThinComputeResultRow): Types.ThinComputeResultRow {
  return {
    children: [],
    value: otherChild.value,
    columnData: getNaNColumnChild(otherChild.columnData),
  };
}

function getNaNColumnChild(
  otherChild: Types.ThinComputeResultColumn
): Types.ThinComputeResultColumn {
  return {
    children: [],
    value: otherChild.value,
    metricMetadata: [[]],
    metricValues: [Number.NaN],
  };
}

function mergeColumnData(
  columnDataA: Types.ThinComputeResultColumn,
  columnDataB: Types.ThinComputeResultColumn
): Types.ThinComputeResultColumn {
  if (!equal(columnDataA.value, columnDataB.value)) {
    throw Error('not the same node value in tree');
  }
  return {
    children: columnDataA.children.map((child, index) =>
      mergeColumnData(child, columnDataB.children[index] ?? getNaNColumnChild(child))
    ),
    value: columnDataA.value,
    metricMetadata: [...columnDataA.metricMetadata, ...columnDataB.metricMetadata],
    metricValues: [...columnDataA.metricValues, ...columnDataB.metricValues],
  };
}

function mergeComputeResults(
  computeResultA: ComputeResultExtended,
  computeResultB: ComputeResultExtended
): ComputeResultExtended {
  if (
    !equal(computeResultA.columnGroupings, computeResultB.columnGroupings) ||
    !equal(computeResultA.rowGroupings, computeResultB.rowGroupings)
  ) {
    throw Error('cannot merge compute results');
  }
  return computeResultA.merge({
    rowGroupings: computeResultA.rowGroupings,
    columnGroupings: computeResultA.columnGroupings,
    requestId: computeResultA.requestId,
    data: mergeComputeResultData(
      computeResultA.data,
      computeResultB.data
    ) as ThinComputeResultRowExtended,
    metrics: [...computeResultA.metrics, ...computeResultB.metrics],
  });
}

export function extractComputeResult(data: any): ComputeResultExtended | null | undefined {
  if (!data) {
    return null;
  } else if (data instanceof ComputeResultExtended) {
    return data;
  } else if (data instanceof CompositeResult && data.computeResults?.length > 0) {
    return data.computeResults.reduce((prevResult, currResult) =>
      prevResult ? mergeComputeResults(prevResult, currResult) : currResult
    );
  } else if (data instanceof CompositeResult && data.compute !== null) {
    return data.compute;
  } else {
    return null;
  }
}

export function getComputeResult(
  widgetData: WidgetData<unknown> | null | undefined,
  config?: Types.Widget
): ComputeResultExtended | null | undefined {
  const computeResult = extractComputeResult(widgetData?.data);
  return isNullish(computeResult) || isNullish(config)
    ? computeResult
    : getOnlyWidgetMetrics(computeResult, config);
}

// get the count of nodes with values for all row and column groupings
export function doSelectionFiltersApplyToResult(
  selectionFilters: readonly Types.AttributeFilter[],
  computeResult: ComputeResultExtended | null | undefined
): boolean {
  if (!selectionFilters.length || !computeResult?.data) {
    return true;
  }
  const selectedValueId = selectionFilters[0].values[0].id;
  if (!selectedValueId) {
    return true;
  }
  return computeResult.data.children.some(child => child.value!.id === selectedValueId);
}

export function getOnlyWidgetMetrics(
  computeResult: ComputeResultExtended | null | undefined,
  config: Types.Widget
): ComputeResultExtended | null | undefined {
  return isNullish(computeResult) || !config.insightPillMetrics?.length
    ? computeResult
    : getMetricsSlice(computeResult, 0, config.metrics.length);
}

export function getOnlyInsightPillMetrics(
  computeResult: ComputeResultExtended | null | undefined,
  config: Types.Widget
): ComputeResultExtended | null | undefined {
  if (isNullish(computeResult)) {
    return computeResult;
  }
  if (!config.insightPillMetrics || config.insightPillMetrics.length === 0) {
    return null;
  }
  return getMetricsSlice(computeResult, config.metrics.length);
}

function getMetricsSlice(
  computeResult: ComputeResultExtended,
  metricIndexStart: number,
  metricIndexEnd?: number
): ComputeResultExtended {
  const indexEnd = metricIndexEnd ?? computeResult.metrics.length;
  // if the indexes match the entire metrics list, just return the compute result
  if (metricIndexStart === 0 && indexEnd === computeResult.metrics.length) {
    return computeResult;
  }

  const slicedData = getThinComputeResultRowSlice(computeResult.data, metricIndexStart, indexEnd);
  return computeResult.merge({
    data: slicedData,
    metrics: computeResult.metrics.slice(metricIndexStart, indexEnd),
  });
}

function getThinComputeResultRowSlice(
  resultRow: ThinComputeResultRowExtended,
  metricIndexStart: number,
  metricIndexEnd: number
): ThinComputeResultRowExtended {
  const children = resultRow.children.map(child =>
    getThinComputeResultRowSlice(child, metricIndexStart, metricIndexEnd)
  );
  return {
    ...resultRow,
    children,
    columnData: getThinComputeResultColumnSlice(
      resultRow.columnData,
      metricIndexStart,
      metricIndexEnd
    ),
  };
}

function getThinComputeResultColumnSlice(
  resultColumn: Types.ThinComputeResultColumn,
  metricIndexStart: number,
  metricIndexEnd: number
): Types.ThinComputeResultColumn {
  const children = resultColumn.children.map(child =>
    getThinComputeResultColumnSlice(child, metricIndexStart, metricIndexEnd)
  );
  return {
    ...resultColumn,
    children,
    metricMetadata: resultColumn.metricMetadata.slice(metricIndexStart, metricIndexEnd),
    metricValues: resultColumn.metricValues.slice(metricIndexStart, metricIndexEnd),
  };
}
