import * as Types from 'types';

export type MatchingPair = {
  partnerAttribute: Types.Attribute | null;
  vendorAttribute: Types.Attribute | null;
};

export const EMPTY_PAIR: MatchingPair = {
  partnerAttribute: null,
  vendorAttribute: null,
};

export type ProductDerivationResultByPartnerIdStr = {
  [key: string]: readonly Types.ProductDerivationConfigurationResult[];
};

export enum MatchStatus {
  UNCHANGED_MATCHED = 'UNCHANGED_MATCHED',
  UNCHANGED_UNMATCHED = 'UNCHANGED_UNMATCHED',
  MATCH_CHANGED = 'MATCH_CHANGED',
  NEWLY_MATCHED = 'NEWLY_MATCHED',
  NEWLY_UNMATCHED = 'NEWLY_UNMATCHED',
}

export type ProductMappingIdentifierTableRow = {
  identifierValue: Types.AttributeValue;
  vendorProductIdentifierValues: readonly Types.ThinAttributeValue[] | null | undefined;
  comparisonVendorProductIdentifierValues: readonly Types.ThinAttributeValue[] | null | undefined;
  matchStatus: MatchStatus;
  // Below are fields only shown in dev mode
  productIds: readonly number[] | undefined;
  matchedVendorProductIds: readonly number[] | undefined;
  comparisonMatchedVendorProductIds: readonly number[] | undefined;
};
