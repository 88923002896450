import History from 'history';
import React, {useMemo} from 'react';
import {useHistory} from 'react-router';

import {useCurrentUser} from 'redux/context/user';
import useSelector from 'redux/selectors/useSelector';
import {withModalBoundary} from 'toolkit/components/Boundary';
import {getPlanningTypeMatch} from 'toolkit/views/utils';
import * as Types from 'types';
import {getPromoAnalysisUrl, isPromoAnalysisEnabled} from 'workflows/utils';

const LazyCalendarEventModal = React.lazy(() => import('widgets/calendars/CalendarEventEditModal'));

const CreateCalendarEventDialog: React.FC<Props> = ({location, onClose}) => {
  const currentUser = useCurrentUser();
  const history = useHistory();

  const planType = getPlanningTypeMatch(location.pathname);
  const analysisCalendar = useSelector(state => state.user.settings?.analysisSettings!.calendar);
  const planSettings = useSelector(state => state.plans.planSettings);

  const calendar = useMemo(() => {
    const calendarFromPlanSettings =
      planType &&
      (planType === Types.PlanType.DEMAND
        ? planSettings?.demandPlanSettings?.calendar
        : planSettings?.inventoryPlanSettings?.calendar);
    return calendarFromPlanSettings || analysisCalendar;
  }, [analysisCalendar, planSettings, planType]);

  return (
    <LazyCalendarEventModal
      analysisSettings={currentUser.settings.analysisSettings}
      calendar={calendar}
      event={null}
      onClose={onClose}
      onEventSaved={event => {
        onClose();
        if (isPromoAnalysisEnabled(currentUser)) {
          history.push(getPromoAnalysisUrl(currentUser.vendor.name, event.id));
        }
      }}
    />
  );
};
CreateCalendarEventDialog.displayName = 'CreateCalendarEventDialog';

type Props = {
  location: History.Location;
  onClose: () => void;
};

export default withModalBoundary(CreateCalendarEventDialog);
