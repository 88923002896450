import moment from 'moment-timezone';

import {isLoading, isFail} from 'redux/actions/helpers';
import {IComputeResultExtended} from 'toolkit/compute/types';
import {WidgetData, createDefaultWidgetData, CompositeResult} from 'toolkit/views/types';
import {ApiError} from 'types/error';

interface WidgetDataQueryAction<T = unknown> {
  readonly data?: T;
  readonly dataKey: unknown;
  readonly error?: ApiError;
  readonly promise?: Promise<any>;
  readonly timestamp: moment.Moment;
  readonly requestId: string;
}

export function updateWidgetState<T = unknown>(
  dataForWidget: WidgetData<T> | null,
  action: WidgetDataQueryAction<T>
): WidgetData<T> {
  const prevWidgetData = dataForWidget || createDefaultWidgetData();
  const lastTimestamp = prevWidgetData.lastUpdated;
  if (lastTimestamp && !action.timestamp.isSameOrAfter(lastTimestamp)) {
    // race condition; multiple requests were sent and the last one completed before
    // an earlier one. This can happen when doing selection and quick deselection.
    return prevWidgetData;
  }

  if (isLoading(action)) {
    return {
      ...prevWidgetData,
      error: null,
      cacheVersion: null,
      isFetching: true,
      lastUpdated: action.timestamp,
      needsRefresh: false,
      requestId: action.requestId,
    };
  } else if (isFail(action)) {
    return {
      data: null,
      cacheVersion: null,
      ephemeralData: prevWidgetData.ephemeralData,
      error: action.error,
      isFetching: false,
      lastUpdated: action.timestamp,
      needsRefresh: false,
      requestId: action.requestId,
    };
  }

  return {
    data: action.data,
    cacheVersion: getCacheVersion(action.data),
    ephemeralData: prevWidgetData.ephemeralData,
    error: null,
    isFetching: false,
    lastUpdated: action.timestamp,
    needsRefresh: false,
    requestId: action.requestId,
  };
}

function getCacheVersion(data: unknown): string | null {
  if (data && typeof data === 'object') {
    if ('compute' in data) {
      return (data as CompositeResult<IComputeResultExtended>).compute?.cacheVersion ?? null;
    } else if ('cacheVersion' in data) {
      return (data as IComputeResultExtended).cacheVersion ?? null;
    }
  }
  return null;
}
