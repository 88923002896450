import equal from 'fast-deep-equal';
import {is as isEqual, List, Map, Set} from 'immutable';
import {match as RouterMatch, matchPath} from 'react-router';

import {CurrentUser} from 'redux/reducers/user';
import {Settings} from 'settings/utils';
import {
  AnalysisEntityType,
  AnalysisManagementEntity,
  DashboardAnalysisEntity,
  TemplateAnalysisEntity,
  WorkflowAnalysisEntity,
} from 'toolkit/analysis/types';
import {AttributeHierarchies, AttributesById} from 'toolkit/attributes/types';
import {writeLocalFile} from 'toolkit/files/utils';
import {
  getAlwaysPassFilterForAttribute,
  getPartnerFilters,
  mergeAttributeFilters,
  toAttributeFilter,
} from 'toolkit/filters/utils';
import {IconSpec} from 'toolkit/icons/types';
import {isGridLikeLayout} from 'toolkit/layout/utils';
import {MetricsByName} from 'toolkit/metrics/types';
import {HIDDEN_SUPER_ADMIN_TAGS, Tags} from 'toolkit/tags/types';
import {
  isViewEditable,
  hasPermission,
  isAtLeast,
  canSeeView,
  PUBLIC_VENDOR_ID,
} from 'toolkit/users/utils';
import {
  AnalysisUrlParams,
  BreadcrumbItem,
  getDefaultViewProperties,
  IViewState,
  ThinViewsById,
  ViewActions,
  ViewUrlParams,
} from 'toolkit/views/types';
import * as Types from 'types';
import {ascendingBy} from 'utils/arrays';
import {assertTruthy} from 'utils/assert';
import {isNullish, isTruthy} from 'utils/functions';
import {
  createDefaultWidget,
  getComparableWidget,
  replaceCurrencyArgumentForWidget,
  replacePeriodsForWidget,
  replaceUnitConversionAttributesForWidget,
  stripNonDataProps as stripWidgetNonDataProps,
  Widgets,
} from 'widgets/utils';

import dashboardIcon from './icons/icon-dashboard.svg';
import {getViewLink} from './view-urls';

export const DASHBOARD_MANAGEMENT_PAGE_SLUG = 'analysis/manage';
export const EVENT_MANAGEMENT_SLUG = 'data/events/manage';

export const DASHBOARD_MANAGEMENT_PATH = `/:vendor/${DASHBOARD_MANAGEMENT_PAGE_SLUG}`;
export const EVENT_MANAGEMENT_PATH = `/:vendor/${EVENT_MANAGEMENT_SLUG}`;
export const EVENT_PATH = '/:vendor/data/events/:eventId(\\d+)';
export const ANALYSIS_PATH = '/:vendor/analysis/:viewSlug?';
export const ANALYSIS_WIDGET_PATH = '/:vendor/analysis/:viewSlug/:widgetId(\\d+)';
export const ANALYSIS_WIDGET_WITH_ID_PATH =
  '/:vendor/analysis/:viewId(-?\\d+)/:viewSlug/:widgetId(\\d+)';
export const ANALYSIS_WITH_ID_PATH = '/:vendor/analysis/:viewId(-?\\d+)/:viewSlug?';
export const ANALYSIS_DETAILS_PATH = '/:vendor/analysis/:viewSlug/*';
export const ANALYSIS_DETAILS_WITH_ID_PATH = '/:vendor/analysis/:viewId(-?\\d+)/:viewSlug/*';
export const EXPERIMENT_PATH = '/:vendor/experiments/:experimentId(\\d+)';
export const EXPERIMENT_WITH_SLUG_PATH =
  '/:vendor/experiments/:experimentId(\\d+)/:experimentSlug?';
export const NEW_EXPERIMENT_PATH = '/:vendor/experiments/new-experiment';
export const PLANNING_PATH = '/:vendor/planning/:planType([a-z]+)';

export const HOME_SLUG = 'home';
export const HOME_PATH = `/:vendor/${HOME_SLUG}`;

export const SALES_DIAGNOSTICS_SLUG = 'diagnostics';
export const SALES_DIAGNOSTICS_PATH = `/:vendor/${SALES_DIAGNOSTICS_SLUG}`;

export const PROMOTIONS_SLUG = 'promotions';
export const PROMOTIONS_PATH = `/:vendor/${PROMOTIONS_SLUG}/:eventId?/:tab?`;

export const WORKFLOW_SLUG = 'workflow';
export const WORKFLOW_PATH = `/:vendor/${WORKFLOW_SLUG}/:workflow`;

export const BULK_EXPORTS_VIEW_SLUG = 'bulk-exports';
const INVISIBLE_INTERNAL_VIEW_SLUGS = [BULK_EXPORTS_VIEW_SLUG];

// view ids of static views (experiment, planning, etc) are negative to
// distinguish from unsaved views
export const UNSAVEABLE_VIEW_ID = -1;
export const PLANNING_PAGE_VIEW_ID = -2;
export const isSaveableViewId = (viewId: number | null | undefined): viewId is number =>
  Boolean(viewId && viewId > 0);

export const DASHBOARD_ICON: IconSpec = {
  alloyIcon: dashboardIcon,
};

export function getPlanningTypeMatch(pathname: string): Types.PlanType | undefined {
  const match = matchPath<{planType: string}>(pathname, {path: PLANNING_PATH});
  if (!match) {
    return undefined;
  }
  const planType = match.params.planType.toLocaleUpperCase();
  if (!(planType in Types.PlanType)) {
    return undefined;
  }
  return Types.PlanType[planType as keyof typeof Types.PlanType];
}

export function getViewPathMatch(pathname: string) {
  for (const path of [
    ANALYSIS_WIDGET_WITH_ID_PATH,
    ANALYSIS_DETAILS_WITH_ID_PATH,
    ANALYSIS_WITH_ID_PATH,
    ANALYSIS_WIDGET_PATH,
    ANALYSIS_DETAILS_PATH,
    ANALYSIS_PATH,
  ]) {
    const match = matchPath<AnalysisUrlParams>(pathname, {path});
    if (match && (!/\*/.test(path) || match.isExact)) {
      if (isDashboardManagementPage(pathname)) {
        return null;
      }
      return match;
    }
  }
  return null;
}

export function isDashboardManagementPage(pathname: string): boolean {
  return !!matchPath(pathname, {path: DASHBOARD_MANAGEMENT_PATH});
}

export function getHomeUrl(vendorName: string) {
  return `/${vendorName}/${HOME_SLUG}`;
}

export function getIntelligenceManagementPageUrl(vendorName: string) {
  return `/${vendorName}/${DASHBOARD_MANAGEMENT_PAGE_SLUG}`;
}

export function getEventsPageUrl(vendorName: string) {
  return `/${vendorName}/${EVENT_MANAGEMENT_SLUG}`;
}

export function getExperimentUrl(vendorName: string, experiment: Types.Experiment) {
  return `/${vendorName}/experiments/${experiment.id}/${experiment.slug}`;
}

export function getViewId(match: RouterMatch<AnalysisUrlParams>) {
  const viewId = match.params.viewId !== undefined ? parseInt(match.params.viewId, 10) : NaN;
  return !Number.isNaN(viewId) ? viewId : null;
}

export function getDetailViewSlugs(match: RouterMatch<AnalysisUrlParams>) {
  return match.params[0] ? List(match.params[0].split('/')) : List.of<string>();
}

export function isBreadcrumbView(breadcrumbs: readonly BreadcrumbItem[]) {
  return breadcrumbs.length > 1;
}

export function isViewHidden(view: Types.ThinView | null, currentUser: Types.User) {
  return isViewPrivate(view) && !isViewOwnedByUser(view, currentUser);
}

export function isViewPrivate(view: Types.ThinView | null): boolean {
  return !!view && view.shareLevel === Types.ShareLevel.PRIVATE;
}

export function canEditSharing(view: Types.View, currentUser: Types.User) {
  return (
    isViewOwnedByUser(view, currentUser) || isAtLeast(currentUser.role, Types.Role.SUPER_ADMIN)
  );
}

export function exportViewAsJSON(filename: string, view: Types.View) {
  writeLocalFile(
    filename,
    new Blob([JSON.stringify(view)], {type: 'application/vnd.alloy-dashboard+json'})
  );
}

export function canEditLinks(currentUser: CurrentUser) {
  return (
    isAtLeast(currentUser.user.role, Types.Role.ADMIN) &&
    hasPermission(currentUser, Types.PermissionKey.DASHBOARD_MANAGE_LINKS)
  );
}

function isViewOwnedByUser(view: Types.ThinView | null, currentUser: Types.User) {
  return view && view.ownerId === currentUser.id;
}

export function isBuiltInView(availableThinViews: ThinViewsById, view: Types.ThinView) {
  return (
    !(view.id === null || availableThinViews.has(view.id)) || view.slug === BULK_EXPORTS_VIEW_SLUG
  );
}

export function isValidView(view: Types.View | null | undefined) {
  return !!view?.slug;
}

function stripNonDataProps(view: Types.View): Types.View {
  return {
    ...view,
    widgets: view.widgets?.map(widget => stripWidgetNonDataProps(widget)),
    weekFormat: Types.WeekFormat.CALENDAR_FORMAT,
    description: null,
    id: null,
    isFixedHeight: false,
    lastModifiedAt: null,
    lastModifiedBy: null,
    layoutType: Types.LayoutType.FLOW,
    name: '',
    ownerId: null,
    tags: [],
    shareLevel: Types.ShareLevel.PRIVATE,
    slug: null,
  };
}

export function isViewRefreshRequired(prevView: Types.View, newView: Types.View) {
  return !isEqual(stripNonDataProps(prevView), stripNonDataProps(newView));
}

export function getNextAvailableName(suggestedName: string, availableViews: ThinViewsById) {
  const existingNames = availableViews.map(view => view.name).toSet();
  if (!existingNames.contains(suggestedName)) {
    return suggestedName;
  }

  let suffix = 2; // eslint-disable-line fp/no-let
  while (existingNames.contains(`${suggestedName} ${suffix}`)) {
    ++suffix;
  }
  return `${suggestedName} ${suffix}`;
}

export function getUsedViewNames(
  allExistingViews: ThinViewsById,
  viewTypes?: readonly Types.ViewType[]
) {
  return allExistingViews
    .filter(view => (view.id ?? 0) > 0)
    .filter(view => viewTypes?.includes(view.type) ?? true)
    .map(view => view.name)
    .valueSeq()
    .toSet()
    .add(HOME_SLUG);
}

export function isViewNameAvailable(
  allExistingViews: ThinViewsById,
  nameToCheck: string,
  viewTypes?: readonly Types.ViewType[]
) {
  return !getUsedViewNames(allExistingViews, viewTypes).includes(nameToCheck);
}

function getComparableView(view: Types.View | null | undefined): Types.View | undefined {
  if (!view) {
    return undefined;
  }

  return {
    ...view,
    filters: [...view.filters]
      .sort(ascendingBy(attrFilter => attrFilter.attributeInstance.attribute.name))
      .map(filter => {
        return {...filter, values: [...filter.values].sort(ascendingBy(value => value.id))};
      }),
    widgets: view.widgets.map(widget => getComparableWidget(widget)),
  };
}

export function isViewDirty(
  originalView: Types.View | null | undefined,
  selectedView: Types.View | null | undefined
) {
  if (!originalView) {
    return true;
  }

  return !isEqual(getComparableView(originalView), getComparableView(selectedView));
}

export function createEmptyView(
  currentUser: Types.User,
  analysisSettings: Types.VendorAnalysisSettings
): Types.View {
  return {
    ...getDefaultViewProperties(),
    calendar: analysisSettings.calendar,
    evaluationPeriod: analysisSettings.dashboardPeriod,
    weekFormat: analysisSettings.weekFormat,
    name: 'New Dashboard',
    ownerId: currentUser.id,
    slug: 'new-dashboard',
    currency: analysisSettings.currency,
    removeDoubleCounting: analysisSettings.removePartnerDoubleCounting,
    ...(currentUser.vendorId === PUBLIC_VENDOR_ID
      ? {
          type: Types.ViewType.TEMPLATE,
          shareLevel: Types.ShareLevel.SHARED_READ_ONLY,
          updateMode: Types.ViewUpdateMode.MANUAL,
        }
      : {}),
  };
}

function filterAttributeComparator(a: Types.Attribute, b: Types.Attribute) {
  if (a.type === b.type) {
    return a.name.localeCompare(b.name);
  }
  return a.type === Types.AttributeType.PRODUCT ? -1 : 1;
}

export function createDefaultView(
  currentUser: CurrentUser,
  analysisSettings: Types.VendorAnalysisSettings,
  defaultAttributeHierarchies: AttributeHierarchies,
  availableMetrics: MetricsByName,
  allGroupings: AttributesById,
  filterAttributes: readonly Types.Attribute[] = []
): Types.View {
  return {
    ...createEmptyView(currentUser.user, analysisSettings),
    layoutType: Types.LayoutType.VERTICAL,
    filters: [...filterAttributes]
      .sort(filterAttributeComparator)
      .map(getAlwaysPassFilterForAttribute),
    widgets: [
      {
        ...createDefaultWidget(
          allGroupings,
          availableMetrics,
          defaultAttributeHierarchies,
          analysisSettings.dashboardPeriod,
          currentUser,
          Types.WidgetType.CHART,
          analysisSettings.currency,
          false
        ),
      },
      {
        ...createDefaultWidget(
          allGroupings,
          availableMetrics,
          defaultAttributeHierarchies,
          analysisSettings.dashboardPeriod,
          currentUser,
          Types.WidgetType.TABLE,
          analysisSettings.currency,
          false
        ),
      },
    ],
  };
}

export function getRecentViews(
  currentUser: CurrentUser,
  views: ThinViewsById,
  viewTimestamps: Map<number, string>,
  maxCount = 10
) {
  return viewTimestamps
    .sort()
    .reverse()
    .slice(0, maxCount)
    .filter((_, viewId) => views.has(viewId))
    .map((_, viewId) => views.get(viewId))
    .filter(isTruthy)
    .filter(view => canSeeView(currentUser, view))
    .toList();
}

export function toThinView(view: Types.ThinView | Types.View): Types.ThinView {
  return 'widgets' in view
    ? {
        description: view.description,
        id: view.id,
        lastModifiedAt: view.lastModifiedAt,
        lastModifiedBy: view.lastModifiedBy,
        archivedAt: view.archivedAt,
        name: view.name,
        ownerId: view.ownerId,
        shareLevel: view.shareLevel,
        tags: view.tags,
        slug: view.slug,
        type: view.type,
        isPublished: view.isPublished,
        packageIds: view.packageIds,
      }
    : view;
}

export function getDeletionMessage(view: Types.ThinView, currentUser: Types.User) {
  const details = 'It will be permanently deleted and you will not be able to undo this action.';
  if (isSharedView(view)) {
    const launchpadMessage = 'This dashboard is used for the launchpad';
    const publicVendorMessage =
      currentUser.vendorId === PUBLIC_VENDOR_ID
        ? ' and has linked versions in all vendor instances'
        : '';
    return `${launchpadMessage}${publicVendorMessage}. Are you sure you want to delete it? ${details}`;
  }
  if (isViewHidden(view, currentUser)) {
    const privateViewMessage = 'This dashboard is private to another user.';
    return `${privateViewMessage} Are you sure you want to delete it? ${details}`;
  }
  return `Are you sure you want to delete the dashboard "${view.name}"? ${details}`;
}

export function replacePeriodsInWidgets(
  widgets: readonly Types.Widget[],
  previousPeriod: Types.DatePeriod,
  newPeriod?: Types.DatePeriod | null
) {
  return newPeriod
    ? widgets.map(widget => replacePeriodsForWidget(widget, previousPeriod, newPeriod))
    : widgets;
}

export function replaceUnitConversionAttributesInWidgets(
  settings: Settings,
  availableMetrics: MetricsByName,
  widgets: readonly Types.Widget[],
  previousAttribute: Types.Attribute | null,
  newAttribute: Types.Attribute | null,
  currency: Types.CurrencyCode
) {
  return widgets.map(widget =>
    replaceUnitConversionAttributesForWidget(
      settings,
      availableMetrics,
      widget,
      previousAttribute,
      newAttribute,
      currency
    )
  );
}

export function replaceCurrencyArgumentInWidgets(
  widgets: readonly Types.Widget[],
  newCurrency: Types.CurrencyCode
) {
  return widgets.map(widget => replaceCurrencyArgumentForWidget(widget, newCurrency));
}

export function removeDoubleCountingPartnersInAttributeFilters(
  view: Types.View,
  partnersToRemove: number[]
): Types.View {
  const newWidgets = view.widgets.map(widget => ({
    ...widget,
    filters: removeDoubleCountingPartnersFromFilters(widget.filters, partnersToRemove),
  }));
  return {
    ...view,
    filters: removeDoubleCountingPartnersFromFilters(view.filters, partnersToRemove),
    widgets: newWidgets,
  };
}

export function removeDoubleCountingPartnersFromFilters(
  filters: readonly Types.AttributeFilter[],
  partnersToRemove: number[]
) {
  const partnerFilters = getPartnerFilters(filters);
  if (partnerFilters.length === 0) {
    return filters;
  }
  const newPartnerFilters = partnerFilters
    .map(filter => ({
      ...filter,
      values: filter.values.filter(
        value => value.id === null || !partnersToRemove.includes(value.id)
      ),
    }))
    .filter(filter => filter.values.length > 0);
  return [
    ...filters.filter(filter => filter.attributeInstance.attribute.name !== 'Partner'),
    ...newPartnerFilters,
  ];
}

const emptyParams = new ViewUrlParams();

export function getDefaultViewStateProps(
  view: Types.View,
  passedParams?: ViewUrlParams | null
): Partial<IViewState> {
  const params = passedParams || emptyParams;
  const filters = getFiltersFromParams(view, params);
  const selectionFilters = getSelectionFiltersFromParams(view, params);
  const calendar = params.calendar || view.calendar;
  const evaluationPeriod = params.evaluationPeriod || view.evaluationPeriod;
  return {
    editingWidgetIndex: null,
    evaluationDate: params.asOfDate,
    computeEvaluationDateTime: null,
    isEditingLayout: false,
    originalWidgets: [],
    proposedWidgets: [],
    view: {
      ...view,
      calendar,
      evaluationPeriod,
      filters,
      widgets: replacePeriodsInWidgets(
        view.widgets,
        view.evaluationPeriod,
        params.evaluationPeriod
      ),
    },
    viewParams: params,
    selectionFiltersByWidgetIndex: selectionFilters,
  };
}

function getFiltersFromParams(
  view: Types.View,
  params: ViewUrlParams
): readonly Types.AttributeFilter[] {
  const attributesFilteredByParams = params.filters.map(filter => filter.attribute.id);
  return mergeAttributeFilters([
    ...view.filters.filter(
      filter =>
        !attributesFilteredByParams.includes(assertTruthy(filter.attributeInstance.attribute.id))
    ),
    ...params.filters.map(toAttributeFilter),
  ]);
}

function getSelectionFiltersFromParams(
  view: Types.View,
  params: ViewUrlParams
): Map<number, readonly Types.AttributeFilter[]> {
  if (isNullish(params.selectionFilters)) {
    return Map();
  }
  const attributeGroupingIds = params.selectionFilters.map(filter => filter.attribute.id);
  const widgetWithMatchingGroupingsIndex = view.widgets.findIndex(
    widget =>
      equal(
        widget.rowGroupings.map(grouping => grouping.attribute.id),
        attributeGroupingIds
      ) ||
      (Widgets[widget.type].isChart &&
        equal(
          widget.columnGroupings.map(grouping => grouping.attribute.id),
          attributeGroupingIds
        ))
  );
  return widgetWithMatchingGroupingsIndex < 0
    ? Map()
    : Map([[widgetWithMatchingGroupingsIndex, params.selectionFilters.map(toAttributeFilter)]]);
}

export function getDashboardAnalysisEntities(
  availableViews: ThinViewsById,
  favoriteViewIds: Set<number>,
  viewTimestamps: Map<number, string | null>,
  currentUser: CurrentUser,
  workflowTypesByViewId: Map<number, readonly Types.Workflow[]> = Map()
): readonly DashboardAnalysisEntity[] {
  const isAtLeastSuperAdmin = isAtLeast(currentUser.user.role, Types.Role.SUPER_ADMIN);
  return getSortedViews(availableViews)
    .filter(view => {
      if (isTemplateView(view)) {
        return false;
      } else if (isWorkflowView(view)) {
        return currentUser.vendor.id === PUBLIC_VENDOR_ID;
      } else {
        return true;
      }
    })
    .map(view =>
      toAnalysisEntity(
        view,
        currentUser.vendor.name,
        favoriteViewIds,
        viewTimestamps,
        isAtLeastSuperAdmin,
        workflowTypesByViewId
      )
    )
    .filter(isDashboardEntity)
    .toArray();
}

export function getTemplateAnalysisEntities(
  availableViews: ThinViewsById,
  favoriteViewIds: Set<number>,
  viewTimestamps: Map<number, string | null>,
  currentUser: CurrentUser
): readonly TemplateAnalysisEntity[] {
  const isAtLeastSuperAdmin = isAtLeast(currentUser.user.role, Types.Role.SUPER_ADMIN);
  return getSortedViews(availableViews)
    .filter(isTemplateView)
    .filter(view => isAtLeastSuperAdmin || view.isPublished)
    .map(view =>
      toAnalysisEntity(
        view,
        currentUser.vendor.name,
        favoriteViewIds,
        viewTimestamps,
        isAtLeastSuperAdmin
      )
    )
    .filter(isTemplateEntity)
    .toArray();
}

function getSortedViews(availableViews: ThinViewsById) {
  return availableViews
    .valueSeq()
    .sortBy(view => view.name)
    .filter(view => !INVISIBLE_INTERNAL_VIEW_SLUGS.includes(view.slug ?? ''));
}

export function toAnalysisEntity<View extends Types.ThinView = Types.ThinView>(
  view: View,
  vendorName: string,
  favoriteViewIds: Set<number> = Set(),
  viewTimestamps: Map<number, string | null> = Map(),
  isAtLeastSuperAdmin = false,
  workflowTypesByViewId: Map<number, readonly Types.Workflow[]> = Map()
): DashboardAnalysisEntity<View> | TemplateAnalysisEntity<View> {
  const isTemplate = isTemplateView(view);
  return {
    id: view.id,
    icon: isTemplate ? getTemplateIcon() : getDashboardIcon(),
    isFavorite: view.id ? favoriteViewIds.has(view.id) : false,
    isPrivate: isViewPrivate(view),
    lastVisited: view.id ? viewTimestamps.get(view.id) : null,
    name: view.name,
    ownerId: view.ownerId,
    url: getViewLink(vendorName, view.id, [assertTruthy(view.slug)]),
    tags: isAtLeastSuperAdmin
      ? view.tags
      : view.tags.filter(tag => !HIDDEN_SUPER_ADMIN_TAGS.includes(tag)),
    type: isTemplate ? AnalysisEntityType.TEMPLATE : AnalysisEntityType.DASHBOARD,
    value: view,
    isAdditionalTemplate: isTemplate && view.tags.includes(Tags.ALLOY_ADDITIONAL_TEMPLATES),
    workflows: workflowTypesByViewId.get(view.id) ?? null,
    packageIds: view.packageIds ?? [],
  };
}

export function isDashboardEntity<T>(
  entity: DashboardAnalysisEntity<T> | TemplateAnalysisEntity<T>
): entity is DashboardAnalysisEntity<T> {
  return entity.type === AnalysisEntityType.DASHBOARD;
}

export function isTemplateEntity<T>(
  entity: DashboardAnalysisEntity<T> | TemplateAnalysisEntity<T>
): entity is TemplateAnalysisEntity<T> {
  return entity.type === AnalysisEntityType.TEMPLATE;
}

export function isWorkflowEntity<T>(
  entity:
    | AnalysisManagementEntity
    | DashboardAnalysisEntity<T>
    | TemplateAnalysisEntity<T>
    | WorkflowAnalysisEntity
): entity is WorkflowAnalysisEntity {
  return entity.type === AnalysisEntityType.WORKFLOW;
}

export function getDashboardIcon(): IconSpec {
  return DASHBOARD_ICON;
}

export function getTemplateIcon(): IconSpec {
  return 'play-circle';
}

export function getPossibleViewActions(
  currentUser: CurrentUser,
  view: Types.View,
  originalView: Types.View | null | undefined,
  breadcrumbs: readonly BreadcrumbItem[],
  favoriteViewIds: Set<number>,
  isEditingLayout: boolean
): ViewActions {
  const {isPresentationMode, isDevelopmentMode} = currentUser;
  const isNewView = !view.id || view.id < 0;
  const isStoredView = !isNewView;
  const isDirty = isViewDirty(originalView, view);
  const hasUnsavedChanges = isDirty || !view.id;

  const savePermission = isNewView
    ? Types.PermissionKey.DASHBOARD_CREATE_DASHBOARD
    : Types.PermissionKey.DASHBOARD_UPDATE_DASHBOARD;

  const isShared = isSharedView(view);
  const canSave =
    isViewEditable(currentUser, view) &&
    !isBreadcrumbView(breadcrumbs) &&
    !isPresentationMode &&
    ((isSharedView(view) && PUBLIC_VENDOR_ID === currentUser.vendor.id) ||
      (!isSharedView(view) && PUBLIC_VENDOR_ID !== currentUser.vendor.id)) &&
    hasPermission(currentUser, savePermission);

  const canSaveAs =
    !isBreadcrumbView(breadcrumbs) &&
    !isPresentationMode &&
    (!(isLaunchpadView(view) || isWorkflowView(view)) ||
      currentUser.vendor.id === PUBLIC_VENDOR_ID) &&
    hasPermission(currentUser, Types.PermissionKey.DASHBOARD_CREATE_DASHBOARD);

  const canArchive =
    isStoredView &&
    canSave &&
    hasPermission(currentUser, Types.PermissionKey.DASHBOARD_ARCHIVE_DASHBOARD);
  const canFavorite = isStoredView;
  const canUnfavorite = favoriteViewIds.contains(view.id) && isStoredView;
  const canToggleFavorite = (canFavorite || canUnfavorite) && !isEditingLayout;

  const superAdminDefaults = {
    canAddPresentationOverrides:
      (isDevelopmentMode || isPresentationMode) && currentUser.vendor.isDemo,
    canExportImport: isDevelopmentMode,
  };
  const defaults = {
    ...superAdminDefaults,
    canEditSettings: !isEditingLayout,
  };

  if (view.archivedAt) {
    return {
      canAddPresentationOverrides: false,
      canArchive,
      canExportImport: false,
      canEditSettings: false,
      canEditLayout: false,
      canToggleFavorite: false,
      canSave,
      canSaveAs: false,
      canShare: false,
    };
  }
  return {
    ...defaults,
    canArchive,
    canEditLayout: isGridLikeLayout(view.layoutType),
    canToggleFavorite,
    canSave: canSave && (isShared || hasUnsavedChanges),
    canSaveAs,
    canShare: !isNewView,
  };
}

export function canDeleteWidgetInView(view: Types.View) {
  return (
    view.layoutType === Types.LayoutType.FLOW ||
    isGridLikeLayout(view.layoutType) ||
    view.widgets.length > 1
  );
}

export function isLaunchpadView(view: Types.View | Types.ThinView | null | undefined): boolean {
  return view?.type === Types.ViewType.LAUNCHPAD;
}

export function isTemplateView(view: Types.View | Types.ThinView | null | undefined): boolean {
  return view?.type === Types.ViewType.TEMPLATE;
}

export function isWorkflowView(view: Types.View | Types.ThinView | null | undefined): boolean {
  return view?.type === Types.ViewType.WORKFLOW;
}

export function isSharedView(view: Types.View | Types.ThinView | null | undefined): boolean {
  return !!view && view.type !== Types.ViewType.DEFAULT;
}

export function removeVendorFromPath(path: string) {
  return path.replace(/^\/.*?\//, '');
}
