import React, {useMemo} from 'react';

import {UserDataActions} from 'redux/actions/user-data';
import {CurrentUser, CurrentUserState} from 'redux/reducers/user';
import useSelector from 'redux/selectors/useSelector';
import useDispatch from 'redux/useDispatch';
import Centered from 'toolkit/components/Centered';
import ErrorPlaceholder from 'toolkit/components/ErrorPlaceholder';
import {UserInfosByEmail, UsersById} from 'toolkit/users/types';
import {hasPermission} from 'toolkit/users/utils';
import * as Types from 'types';
import {useImmediateEffect} from 'utils/hooks';
import {getCombinedStatus, Status} from 'utils/status';

export function withCurrentUser<P>(Component: React.ComponentType<P & CurrentUserProps>) {
  const CurrentUserComponent: React.FunctionComponent<P> = props => {
    const currentUser = useCurrentUser();
    return <Component {...props} currentUser={currentUser} />;
  };

  return CurrentUserComponent;
}

export function withPermissionGuard<P extends React.JSX.IntrinsicAttributes>(
  permission: Types.PermissionKey,
  Component: React.ComponentType<P>
) {
  const GuardedComponent: React.FunctionComponent<P> = props => {
    const currentUser = useCurrentUser();

    if (hasPermission(currentUser, permission)) {
      return <Component {...props} />;
    } else {
      return (
        <Centered>
          <ErrorPlaceholder error={`User not authorized. Missing permission: ${permission}`} />
        </Centered>
      );
    }
  };

  return GuardedComponent;
}

export function useCurrentUser(): CurrentUser {
  const currentUserState = useSelector(state => state.user);
  return useMemo(() => getCurrentUserFromState(currentUserState), [currentUserState]);
}

export function useMaybeLoggedInUser(): CurrentUserState {
  return useSelector(state => state.user);
}

export function getCurrentUserFromState(currentUserState: CurrentUserState): CurrentUser {
  return {
    ...currentUserState,
    user: {
      ...currentUserState.user!,
      id: currentUserState.user!.id!,
    },
    userInfo: currentUserState.userInfo!,
    permissionKeys: currentUserState.permissionKeys,
    vendor: {
      ...currentUserState.vendor!,
      id: currentUserState.vendor!.id!,
    },
    settings: {
      analysisSettings: currentUserState.settings!.analysisSettings!,
      planSettings: currentUserState.settings?.planSettings,
    },
  };
}

export function useUserData(): [UserInfosByEmail, UsersById, Status] {
  const dispatch = useDispatch();
  const allUserInfos = useSelector(state => state.userData.allUserInfos);
  const allUsersById = useSelector(state => state.userData.allUsersById);

  const fetchingUserData = useSelector(state =>
    getCombinedStatus(
      state.userData.fetchingAllUsersStatus,
      state.userData.fetchingAllUserInfoStatus
    )
  );

  useImmediateEffect(() => {
    if (fetchingUserData === Status.unstarted) {
      dispatch(UserDataActions.fetchAllUserData());
    }
  }, [dispatch, fetchingUserData, allUserInfos, allUsersById]);

  return [allUserInfos, allUsersById, fetchingUserData];
}

/** Extend from this type when wrapping a component with withCurrentUser(). */
export interface CurrentUserProps {
  currentUser: CurrentUser;
}
