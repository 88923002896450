import {canViewCurrencyConversionRates} from 'data/currency-conversion-rates/utils';
import {CurrentUser} from 'redux/reducers/user';
import {isProductMappingWizardVisible} from 'settings/product-mapping-configuration/utils';
import {ToolboxGroup, ToolboxItem} from 'toolkit/components/SidebarToolbox';
import {isDemandPlanningApplicationEnabled} from 'toolkit/plans/utils';
import {hasPermission, isAtLeast, PUBLIC_VENDOR_ID} from 'toolkit/users/utils';
import {EVENT_MANAGEMENT_SLUG} from 'toolkit/views/utils';
import * as Types from 'types';
import {isTruthy} from 'utils/functions';

function hasSufficientPrivileges(currentUser: CurrentUser) {
  return (item: ToolboxGroup | ToolboxItem) =>
    (item.requiredRole === undefined || isAtLeast(currentUser.user.role, item.requiredRole)) &&
    (item.requiredFeatureFlag === undefined ||
      currentUser.featureFlags.includes(item.requiredFeatureFlag)) &&
    hasPermission(currentUser, item.requiredPermission);
}

export function getDataPageSidebarOptions(currentUser: CurrentUser): readonly ToolboxGroup[] {
  const items: readonly ToolboxGroup[] = [
    {
      name: 'Integrations',
      items: [
        currentUser.vendor.id !== PUBLIC_VENDOR_ID && {
          displayName: 'Status',
          name: 'data/status',
          description: 'See latest data status and fix issues',
          keywords: ['Data', 'Status'],
          icon: 'project-diagram' as const,
        },
        isAtLeast(currentUser.user.role, Types.Role.SUPER_ADMIN) && {
          displayName: 'Sources',
          name: 'data/sources',
          description: 'Manage active integrations',
          keywords: ['Sources', 'Integrations'],
          icon: 'database' as const,
        },
        {
          displayName: 'Destinations',
          name: 'data/destinations',
          description: 'Export data to other systems',
          requiredRole: Types.Role.ADMIN,
          requiredPermission: Types.PermissionKey.DESTINATIONS_VIEW_DESTINATIONS,
          keywords: ['Destinations', 'sftp', 'email'],
          icon: 'cloud-download-alt' as const,
        },
        {
          displayName: 'Integrations',
          name: `data/integrations`,
          description: 'Browse 350+ integrations in Alloy.ai',
          keywords: ['Marketplace'],
          icon: 'store' as const,
        },
        {
          displayName: 'Files',
          name: `data/files`,
          description: 'See raw data files',
          requiredRole: Types.Role.ADMIN,
          icon: 'file' as const,
        },
        {
          displayName: 'Forwarded Files',
          name: `data/forwarded-files`,
          description: 'Manage file forwarding rules',
          requiredRole: Types.Role.SUPER_ADMIN,
          requiredPermission: Types.PermissionKey.DATA_FORWARD_FILE,
          icon: 'envelope' as const,
        },
        hasPermission(currentUser, Types.PermissionKey.IMPORT_UPLOAD_FILE) && {
          displayName: 'Import',
          name: `data/import`,
          description: 'Upload data into Alloy.ai',
          icon: 'upload' as const,
          requiredRole: Types.Role.ADMIN,
          requiredPermission: Types.PermissionKey.IMPORT_UPLOAD_FILE,
        },
      ].filter(isTruthy),
      requiredRole: Types.Role.USER,
    },
    {
      name: 'Master Data',
      items: [
        currentUser.vendor.id !== PUBLIC_VENDOR_ID && {
          displayName: 'Products',
          name: `data/products`,
          description: 'Manage your products',
          icon: 'cubes' as const,
        },
        isProductMappingWizardVisible(currentUser) && {
          displayName: 'Product Mapping Wizard',
          name: `data/product-mapping-wizard`,
          description: 'Manage your product mapping configuration',
          icon: 'drafting-compass' as const,
          requiredRole: Types.Role.ADMIN,
        },
        {
          displayName: 'Locations',
          name: `data/locations`,
          description: 'Manage custom locations',
          icon: 'map' as const,
        },
      ].filter(isTruthy),
      requiredRole: Types.Role.USER,
    },
    {
      name: 'Analytics Data',
      items: [
        {
          displayName: 'Events',
          name: EVENT_MANAGEMENT_SLUG,
          description: 'Manage and analyze your promotions and events',
          keywords: ['Events', 'Campaign', 'Promotion'],
          icon: 'calendar-alt' as const,
          requiredPermission: Types.PermissionKey.EVENTS_MANAGE_EVENTS,
        },
        canViewCurrencyConversionRates(currentUser) && {
          displayName: 'Exchange Rates',
          name: `data/currency-conversion-rates`,
          description: 'Edit and setup custom exchange rates',
          keywords: ['Currency', 'Money', 'Dollar', 'Currencies', 'Conversion'],
          icon: 'dollar-sign' as const,
        },
        isDemandPlanningApplicationEnabled(currentUser) && {
          displayName: 'Planning Inputs',
          name: `data/planning-inputs`,
          description: 'Manage custom inputs to the planning application',
          icon: 'table' as const,
        },
      ].filter(isTruthy),
      requiredRole: Types.Role.USER,
    },
  ];

  return items
    .filter(isTruthy)
    .filter(hasSufficientPrivileges(currentUser))
    .map(group => {
      return {
        ...group,
        items: group.items.filter(hasSufficientPrivileges(currentUser)),
      };
    })
    .filter(group => group.items.length > 0);
}
