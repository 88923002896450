import {List} from 'immutable';

export function fromArray<T>(array: readonly any[], ValueClass: any): List<T> {
  return List<T>(array.map(item => ValueClass.fromJS(item)));
}

export function allEnumValues<T extends string>(type: Record<T, T>): readonly T[] {
  return Object.values(type);
}

type RequiredNotNull<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

export type NotNull<T, K extends keyof T> = T & RequiredNotNull<Pick<T, K>>;

export type Saved<T extends {id: number | null}> = NotNull<T, 'id'>;
